import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import { ClipLoader } from "react-spinners";
import { FaTrashAlt } from "react-icons/fa";
import { ErrorMessage, Formik } from "formik";
import InputStyled from "../../components/styles/InputStyled";
import Table from "../../components/styles/Table";
import requests from "../../services/requests";
import { colors } from "../../assets/colors";
import Form from "./styles";

export default function FormManual() {
    const [source, setSource] = useState();
    const [percent, setPercent] = useState({});
    const [progress, setProgress] = useState();
    const [stagesList, setStagesList] = useState([]);

    const removeTempFile = ({ values, key, setValues, setErrors }) => {
        const form = document.getElementById("form");
        form.reset();
        setErrors({});

        delete values.files[key];

        setValues({ ...values });
        setPercent({});
    };

    useEffect(() => {
        //Get Stage
        requests.getStagesList().then(response => {
            const _stages = response.data?.map(option => {
                return { value: option.identifier, label: option.description };
            });
            setStagesList(_stages);
        });

        const cancel = Axios.CancelToken;
        setSource(cancel.source);
        /*return () => {
            onSuccess();
            return source?.cancel();
        };*/
    }, []);
    useEffect(() => {
        if (progress) {
            setPercent({ ...percent, ...progress });
        }
    }, [progress]);

    return (
        <Formik
            initialValues={{
                files: {},
                stages: {},
                modalities: {},
            }}
            onSubmit={(values, { setFieldError, setSubmitting }) => {
                setSubmitting(true);
                const { files } = values;

                const form = document.getElementById("form");
                form.reset();

                if (!Object.keys(files).length) {
                    toast.error("Clique e escolha um arquivo ou arraste.");
                }

                Object.entries(files).forEach(([key, file]) => {
                    const config = {
                        onUploadProgress: function (progressEvent) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setProgress({ [key]: percentCompleted });
                        },
                        cancelToken: source.token,
                    };
                    const docs = new FormData();
                    docs.append("file_upload[file]", file);

                    requests
                        .saveProcessManual(docs, config)
                        .then(() => {
                            delete values.files[key];
                            setSubmitting(false);
                            toast.success(
                                <div>
                                    {file.name} <br /> enviado com sucesso
                                </div>
                            );
                        })
                        .catch(() => {
                            setSubmitting(false);
                            toast.error(
                                <div>
                                    {file.name} <br /> falhou, tente novamente.
                                </div>
                            );
                        });
                });
            }}
        >
            {({ setValues, handleSubmit, isSubmitting, values, setFieldValue, setErrors }) => (
                <Form id="form" onSubmit={handleSubmit}>
                    <InputStyled
                        name="files"
                        id="files"
                        accept=".xlsm"
                        multiple={true}
                        onChange={e => {
                            const files = Array.from(e.target?.files);
                            files.forEach(file => {
                                const key = file.name.replaceAll(".", "") + file.lastModified;
                                setFieldValue(`files[${key}]`, file);
                            });
                        }}
                    />
                    {!Object.keys(values.files).length && (
                        <ErrorMessage component="p" className="text-red mb-4 font-light text-xs" name={`files`} />
                    )}
                    <>
                        <Table>
                            <tbody>
                                {Object.entries(values.files).map(([key, file]) => (
                                    <tr key={key}>
                                        <td colSpan="3">
                                            {file.name}
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name={`files[${key}]`}
                                            />
                                        </td>
                                        <td>
                                            <div className="flex items-center">
                                                <button
                                                    className="p-2 rounded-md bg-canal_vermelho"
                                                    type="button"
                                                    onClick={() => removeTempFile({ values, key, setValues, setErrors })}
                                                >
                                                    <FaTrashAlt color="#fff" />
                                                </button>
                                                {percent[key] > 0 && percent[key] < 100 && (
                                                    <>
                                                        <span className="mx-2">{percent[key]}%</span>
                                                        <ClipLoader size={20} color={colors.roxo_oficial} loading={true} />
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <section>
                            <button
                                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        Enviando <ClipLoader size={10} loading={isSubmitting} />
                                    </>
                                ) : (
                                    <>Enviar</>
                                )}
                            </button>
                        </section>
                    </>
                </Form>
            )}
        </Formik>
    );
}
